export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Infiniti-US',
  hostName: '',
  environmentName: 'staging',
  appName: 'Infiniti-US Analytics',
  ssoPassword: 'ShiftDigital#123',
  defaultChartColors: ['#020B24', '#B0A591', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  applicationInsights: {
    instrumentationKey: '0f50679e-b9d4-4ee5-a2ed-f53e87bfa74b'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Central Standard Time',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  baseApiUri: 'https://infinitius-analytics-api-staging.azurewebsites.net/api',
  baseV5ApiUri: 'https://infinitius-analytics-apiv5-staging.azurewebsites.net/api',
  authUri: 'https://infinitius-analytics-api-staging.azurewebsites.net/token',
  retailerToolsIncludeAuth: true,
  retailerToolsBaseUri: 'https://infinitius-retailertools-web-staging.azurewebsites.net',
  mysteryShopBaseUri: 'https://mysteryshop-staging.infinitidigitalprogram.com',
  favIconPath: '/assets/icons/clients/infiniti-us/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: true,
  useV5WebsiteOverview: false,
  v5Reports: [
    'LeadsOverview',
    'LeadTransactionsOverview',
    'SalesOverview',
    'WebsiteOverview',
    'DigitalRetailingOverview',
    'ChatOverview',
    'DigitalAdvertisingDashboard'
  ]
};
