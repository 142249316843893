import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'leads',
      category: 'leads',
      default: true,
      defaultRoute: '/leads/overview',
      displayName: 'Leads',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: undefined,
      children: [
        {
            parent: '/leads',
            route: 'overview',
            displayName: 'Overview',
            iconClass: 'fa fa-users',
            pdfEnabled: false,
            roles: undefined,
            breadcrumbHelpEnabled: true
        },
        {
          parent: '/leads',
          route: 'transactions-overview',
          displayName: 'Transactions',
          iconClass: 'fa fa-exchange',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
      ]
    },
    {
      id: 'sales',
      category: 'sales',
      default: true,
      defaultRoute: '/sales/overview',
      displayName: 'Sales',
      displayInNav: true,
      iconClass: 'fa fa-usd',
      roles: undefined,
      children: [
        {
          parent: '/sales',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined,
          breadcrumbHelpEnabled: true
        }
      ]
    },
    {
      id: 'website',
      category: 'website',
      defaultRoute: '/website/website-overview',
      displayName: 'Website',
      displayInNav: true,
      iconClass: 'fa fa-globe',
      roles: undefined,
      children: [
        {
          parent: '/website',
          route: 'website-overview',
          displayName: 'Overview',
          pdfEnabled: true,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        }
      ]
    },
    {
      id: 'provider-tools',
      category: 'provider-tools',
      default: true,
      defaultRoute: '/provider-tools/digital-retailing-overview',
      displayName: 'Provider Tools',
      displayInNav: true,
      iconClass: 'fa fa-building',
      roles: undefined,
      children: [
        // E-Commerce
        {
          parent: '/provider-tools',
          route: 'digital-retailing-overview',
          displayName: 'E-Commerce',
          pdfEnabled: true,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
        // Chat
        {
          parent: '/provider-tools',
          route: 'chat-overview',
          displayName: 'Chat',
          pdfEnabled: true,
          iconClass: 'fa fa-users',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
      ]
    },
    {
      id: 'digAd',
      category: 'digAd',
      default: true,
      defaultRoute: '/digAd/dashboard',
      displayName: 'Digital Advertising',
      displayInNav: true,
      iconClass: 'fa fa-desktop',
      roles: undefined,
      children: [
        {
          parent: '/digAd',
          route: 'dashboard',
          displayName: 'Overview',
          pdfEnabled: true,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        }
      ]
    },
    // Mystery Shop external link
    {
      id: 'externalTools',
      category: 'externalTools',
      defaultRoute: '',
      displayName: 'External Tools',
      displayInNav: true,
      iconClass: 'fa fa-external-link',
      roles: undefined,
      externalTool: true,
      disableForDealerGroups: true,
      children: [
        // {
        //   parent: '/externalTools',
        //   route: 'mysteryShop',
        //   displayName: 'Mystery Shop',
        //   iconClass: 'fa fa-wrench',
        //   roles: undefined,
        //   breadcrumbHelpEnabled: true
        // },
        {
          parent: '/externalTools',
          route: 'retailerTools',
          displayName: 'Retailer Tools',
          iconClass: 'fa fa-wrench',
          roles: undefined,
          breadcrumbHelpEnabled: true
        }
      ]
    },
    {
        id: 'resources',
        category: 'resources',
        defaultRoute: '/resources/resources',
        displayName: 'Retailer Resources',
        displayInNav: true,
        iconClass: 'fa fa-file',
        roles: undefined,
        disableForDealerGroups: true,
        children: [
            {
                parent: '/resources',
                route: 'resources',
                displayName: 'Retailer Resources',
                iconClass: 'fa fa-file',
                roles: undefined,
            }
        ]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', "System Analyst"],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"]
        }, 
        {
          parent: '/management',
          route: 'report-views-configuration',
          displayName: 'Report Views',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/create',
              parent: 'report-views-configuration',
              route: 'create/:id',
              displayName: 'Create Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'report-views-configuration/update',
              parent: 'report-views-configuration',
              route: 'update/:id',
              displayName: 'Edit Report View',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, 
        {
          parent: '/management',
          route: 'data-sets-configuration',
          displayName: 'Data Sets',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create/:id',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/create',
              parent: 'data-sets-configuration',
              route: 'create/project/:queryConfigId',
              displayName: 'Create Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'data-sets-configuration/update',
              parent: 'data-sets-configuration',
              route: 'update/:id',
              displayName: 'Edit Data Set',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, 
        {
          parent: '/management',
          route: 'query-configuration',
          displayName: 'Queries',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/create',
              parent: 'query-configuration',
              route: 'create/:id',
              displayName: 'Create Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/update',
              parent: 'query-configuration',
              route: 'update/:id',
              displayName: 'Edit Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'query-configuration/test',
              parent: 'query-configuration',
              route: 'test/:id',
              displayName: 'Test Query',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        }, 
        {
          parent: '/management',
          route: 'filter-configuration',
          displayName: 'Filters',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"],
          children:
          [
            {
              id: 'filter-configuration/create',
              parent: 'filter-configuration',
              route: 'create',
              displayName: 'Create Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
            {
              id: 'filter-configuration/update',
              parent: 'filter-configuration',
              route: 'update/:id',
              displayName: 'Edit Filter',
              iconClass: 'fa fa-code',
              roles: ['System Administrator'],
            },
          ]
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', "System Analyst"]
          },
          {
              parent: '/management',
              route: 'settings',
              displayName: 'Settings',
              iconClass: 'fa fa-code',
              roles: ['System Administrator']
          },
      ]
    }
  ];
}
